<template>
  <div class="commodity">
    <img
      src="https://cdn.bubbleplan.cn/static/home/backPage.png"
      alt=""
      class="commodity-back"
      @click="back()"
    />

    <div class="commodity-ul flex">
      <div
        v-for="(item, index) in productList"
        :key="index"
        class="commodity-ul-li flex"
        @click="buyimmediately(item)"
      >
        <img :src="item.img" alt="" class="commodity-ul-li-img" />
        <div class="commodity-ul-li-text flex">
          <div class="commodity-ul-li-text-name">
            {{ item.activityName }}
          </div>
          <!-- <div class="commodity-ul-li-text-pic">￥{{ item.price }}</div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      page: {
        pageNum: 1,
        pageSize: 20,
      },
      productList: [],
      productListButton: false,
    };
  },
  mounted() {
    this.activityProductList();
  },
  methods: {
    buyimmediately(item) {
      if (this.productListButton) {
        this.$message.error(`即将开售`);
        return;
      }
      this.$router.push({
        path: "/home/commodityDetails/commodityDetails",
        query: {
          ...item,
        },
      });
    },
    //获取首发列表
    async activityProductList() {
      const { data, code } = await this.$ajax.activityProductList({
        ...this.page,
      });
      this.productList = data.list;
      if (this.productList.length == 0) {
        this.productList = [
          {
            activityName: "恐龙族盲盒",
            img: "https://cdn.bubbleplan.cn/commodity/dinosaurblindbox1.png",

            price: 299,
          },
          {
            activityName: "斯拉克族盲盒",
            img: "https://cdn.bubbleplan.cn/commodity/TheSlarkblindbox1.png",

            price: 299,
          },
          {
            activityName: "精灵族盲盒",
            img: "https://cdn.bubbleplan.cn/commodity/theElvenblindbox1.png",

            price: 299,
          },
          {
            activityName: "溪灵族盲盒",
            img: "https://cdn.bubbleplan.cn/commodity/creekSpiritblindbox1.png",

            price: 299,
          },
        ];
        this.productListButton = true;
      } else {
        this.productListButton = false;
      }
    },
    selectItem(item) {
      // 移除所有元素的激活和隐藏类
      const items = document.querySelectorAll(".item");
      const imgS = document.querySelectorAll(".img");
      console.log(imgS[item].classList);

      const index = item;
      if (
        items[item].classList[2] == "active" ||
        items[item].classList[3] == "active"
      ) {
        console.log(2222222);
        imgS[item].classList.remove("item-img1", "item-img");
        items.forEach((el) => {
          el.classList.remove(
            "active",
            "hidden-left",
            "hidden-right",
            "hidden-middle"
          );
        });
      } else {
        // 根据索引添加相应的类
        if (index === 0) {
          // 第一个元素，右边元素隐藏
          items[1].classList.add("hidden-right");
          items[2].classList.add("hidden-right");
          imgS[0].classList.add("item-img");
        } else if (index === 1) {
          // 中间元素，左边和右边元素隐藏
          items[0].classList.add("hidden-left");
          items[1].classList.add("hidden-middle");
          items[2].classList.add("hidden-right");
          imgS[1].classList.add("item-img");
        } else {
          // 第三个元素，左边元素隐藏
          items[0].classList.add("hidden-left");
          items[1].classList.add("hidden-left");
          imgS[2].classList.add("item-img1");
        }
        // 给当前元素添加激活类
        items[index].classList.add("active");
      }
      //   if(items[item])
    },
    back() {
      history.back();
    },
  },
};
</script>

<style lang="scss">
.commodity {
  width: 100%;
  height: calc(100vh - 94px);
  //   min-height: calc(100vh - 94px);
  background: url("https://cdn.bubbleplan.cn/static/home/gather.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
  padding: 123px 0 0 387px;

  &-back {
    position: absolute;
    width: 72px;
    height: 72px;
    top: 66px;
    left: 100px;
  }
  &-back:hover {
    cursor: pointer;
  }
  .commodity-ul::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  .commodity-ul {
    height: 650px;
    overflow: auto;
    flex-wrap: wrap;
    &-li:hover {
      cursor: pointer;
    }

    &-li {
      width: 564px;
      height: 562px;
      background: url("https://cdn.bubbleplan.cn/static/home/Group10407.png")
        no-repeat;
      background-size: 100% 100%;
      margin-right: 33px;
      margin-bottom: 20px;
      padding: 25px 33px 0;
      flex-direction: column;
      align-items: center;
      &-img {
        width: 417px;
        height: 417px;
      }
      &-text {
        width: 100%;
        margin-top: 25px;
        align-items: center;
        // justify-content: space-between;
        justify-content: center;
        &-name {
          color: #574352;
          font-family: "TsangerYuMo";
          font-size: 30px;
          font-weight: bold;
          text-align: center;
        }
        &-pic {
          color: #feecd2;
          font-family: "TsangerYuMo";
          font-size: 32px;
          font-weight: bold;
          text-shadow: -2px -2px 0 #8d5684, 2px -2px 0 #8d5684,
            -2px 2px 0 #8d5684, 2px 2px 0 #8d5684;
          /* 模拟描边效果 */
        }
      }
    }
  }
}
</style>